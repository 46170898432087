import {
  camelCase,
} from 'lodash';

// TODO: API should provide initial and stored in localstorage for defaults?
// PRICES 2022
const CAPACITY_PRICES = {
  exitZone: {
    annual: 0.00229019,
    quarterly: 0.00251921,
    monthly: 0.00286274,
    daily: 0.00343529,
    hourly: 0.00389333,
  },
  imatra: {
    annual: 0.00039115,
    quarterly: 0.00043027,
    monthly: 0.00048894,
    daily: 0.00058673,
    hourly: 0.00066496,
  },
  biogas: {
    annual: 0.00039115,
    quarterly: 0.00043027,
    monthly: 0.00048894,
    daily: 0.00058673,
    hourly: 0.00066496,
  },
  lngHamina: {
    annual: 0.00039115,
    quarterly: 0.00043027,
    monthly: 0.00048894,
    daily: 0.00058673,
    hourly: 0.00066496,
  },
  lngInkoo: {
    annual: 0.00039115,
    quarterly: 0.00043027,
    monthly: 0.00048894,
    daily: 0.00058673,
    hourly: 0.00066496,
  },
};

// TODO: API should provide these
const CAPACITY_PRODUCTS = {
  ANNUAL: 'annual',
  QUARTERLY: 'quarterly',
  MONTHLY: 'monthly',
  DAILY: 'daily',
  HOURLY: 'hourly',
};
const CAPACITY_PRODUCTS_NAMES = Object.values(CAPACITY_PRODUCTS);

// TODO: API should provide these
const CAPACITY_MONTH_PRODUCTS = [
  CAPACITY_PRODUCTS.ANNUAL,
  CAPACITY_PRODUCTS.QUARTERLY,
  CAPACITY_PRODUCTS.MONTHLY,
];

// TODO: API should provide these
const CAPACITY_TYPES = {
  EXITZONE: 'exitZone',
  IMATRA: 'imatra',
  BIOGAS: 'biogas',
  LNG_HAMINA: 'lngHamina',
  LNG_INKOO: 'lngInkoo',
};

const CAPACITY_TYPES_ENABLED = {
  [CAPACITY_TYPES.EXITZONE]: true,
  [CAPACITY_TYPES.IMATRA]: true,
  [CAPACITY_TYPES.BIOGAS]: true,
  [CAPACITY_TYPES.LNG_HAMINA]: true,
  [CAPACITY_TYPES.LNG_INKOO]: true,
};

const CAPACITY_TYPES_API = Object.assign({},
  ...Object.entries(CAPACITY_TYPES).map(([key, value]) => ({
    [key]: camelCase(value),
  })));
const CAPACITY_TYPES_API_NAMES = Object.values(CAPACITY_TYPES_API);

const CAPACITY_DATE_TYPES = {
  START: 'start',
  END: 'end',
};
const CAPACITY_DATE_TYPES_NAMES = Object.values(CAPACITY_DATE_TYPES);

const QUARTAL_START_MONTHS = ['01', '04', '07', '10'];
const QUARTAL_END_MONTHS = ['03', '06', '09', '12'];

// TODO: API should provide these
const ANNUAL_START_MONTHS = {
  [CAPACITY_TYPES.EXITZONE]: '01',
  [CAPACITY_TYPES.BIOGAS]: '01',
  [CAPACITY_TYPES.LNG_HAMINA]: '01',
  [CAPACITY_TYPES.LNG_INKOO]: '01',
  [CAPACITY_TYPES.IMATRA]: '10',
};

export {
  ANNUAL_START_MONTHS,
  CAPACITY_PRICES,
  CAPACITY_PRODUCTS,
  CAPACITY_PRODUCTS_NAMES,
  CAPACITY_MONTH_PRODUCTS,
  CAPACITY_TYPES,
  CAPACITY_TYPES_ENABLED,
  CAPACITY_TYPES_API,
  CAPACITY_TYPES_API_NAMES,
  CAPACITY_DATE_TYPES,
  CAPACITY_DATE_TYPES_NAMES,
  QUARTAL_START_MONTHS,
  QUARTAL_END_MONTHS,
};
