import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Decimal } from 'decimal.js';

import {
  CAPACITY_PRICES,
  CAPACITY_PRODUCTS,
  CAPACITY_TYPES,
} from '../constants/capacities';
import { UNITS, UNITS_PER_HOUR } from '../constants/units';
import { LOCATION_POINTS } from '../constants/nominations';
import {
  getGasDay,
  startOfGasDay,
  endOfGasDay,
  hoursInGasDay,
  startOfOngoingGasDay,
} from './gasday';
import { getDatesInRange, getStartOfNthPeriod } from './dateTimeHelpers';
import { STATUS } from '../constants/status';
import { NETWORKS } from '../constants/marketParties';

const moment = extendMoment(Moment);

const isAnnualImatraSpecialCase = (period, type, start) => (
  period === CAPACITY_PRODUCTS.ANNUAL
  && type === CAPACITY_TYPES.IMATRA
  && moment(start).isBefore(moment('2020-10-01'))
);

const productInMomentTerm = {
  annual: 'years',
  quarterly: 'quarters',
  monthly: 'months',
  daily: 'days',
  hourly: 'hours',
};

const getEndDateForProduct = (startDate, quantity, product) => {
  const end = moment(startDate).add(quantity, productInMomentTerm[product]);
  return product === 'hourly' ? end.toISOString() : getGasDay(end);
};

const getTimeDifferenceInQty = (start, end, product) => {
  if (productInMomentTerm[product] === undefined) {
    return 0;
  }

  if (product === 'hourly') {
    return moment(end).diff(start, productInMomentTerm[product]);
  }

  return startOfGasDay(end).diff(start, productInMomentTerm[product]) + 1;
};

const getEndDate = ({
  period, type, start, quantity,
}) => (isAnnualImatraSpecialCase(period, type, start)
  ? getEndDateForProduct(start, 9, 'monthly')
  : getEndDateForProduct(start, quantity, period));

const hoursInBooking = (product) => {
  const {
    period,
    quantity,
    start,
  } = product;

  const endDate = getEndDate(product);

  return period === CAPACITY_PRODUCTS.HOURLY
    ? quantity
    : endOfGasDay(endDate).diff(startOfGasDay(start), 'hours');
};

const getActivePrices = (prices = [], timestamp) => {
  const activePrices = [...prices].reverse().find(item => item.timestamp <= timestamp) || {};
  return activePrices.values || CAPACITY_PRICES; // Fall back to hardcoded prices in case of error
};

const getActivePricesForCapacityType = (type, prices = [], timestamp) => {
  if (!type) {
    return {};
  }
  const values = getActivePrices(prices, timestamp);
  return values[type] || CAPACITY_PRICES[type];
};

const getInstanceStart = (instance) => {
  const { period, start } = instance;
  const instanceStart = period === CAPACITY_PRODUCTS.HOURLY
    ? moment.utc(start || startOfOngoingGasDay())
    : startOfGasDay(start || getGasDay());
  return instanceStart.toISOString();
};

const calculateCapacityPrice = (instance, capacityPrices) => {
  const {
    type,
    period,
    capacity,
  } = instance;

  if (!type || productInMomentTerm[period] === undefined || Number.isNaN(Number.parseFloat(capacity))) {
    return 0;
  }

  const activePrices = getActivePrices(capacityPrices, getInstanceStart(instance));
  const numberOfHours = hoursInBooking(instance);
  const capacityPrice = new Decimal(activePrices[type][period]);
  const decimalPrice = capacityPrice.times(numberOfHours).times(capacity);
  const price = decimalPrice.toNumber();

  return Number.isNaN(price) ? 0 : price;
};

const getTotalPriceOfProducts = products => (
  products.reduce(((totalPrice, product) => totalPrice.add(product.price)), new Decimal(0))
);

const getHourlyAggregate = products => products.reduce(
  (aggregate, {
    period, start, quantity, capacity,
  }) => (
    period === CAPACITY_PRODUCTS.HOURLY && start && quantity && Number(capacity)
      ? aggregate + quantity * Number(capacity)
      : aggregate
  ),
  0,
);

const getDailyAggregates = (products) => {
  const aggregatesByDay = products.reduce(
    (aggregates, {
      period, start, quantity, capacity,
    }) => {
      if (period !== CAPACITY_PRODUCTS.DAILY || !start || !quantity || !Number(capacity)) {
        return aggregates;
      }

      const end = moment.utc(start).add(quantity - 1, 'days').format('YYYY-MM-DD');
      const dates = getDatesInRange(start, end);
      const aggregate = dates.reduce((dailyAggregates, date) => {
        const oldValue = dailyAggregates[date] || aggregates[date] || 0;
        return {
          ...dailyAggregates,
          [date]: oldValue + Number(capacity * hoursInGasDay(date)),
        };
      }, {});

      return { ...aggregates, ...aggregate };
    },
    {},
  );

  return Object.entries(aggregatesByDay).map(([date, value]) => ({ date, value }));
};


const getHoursInAGasDay = (date) => {
  const start = startOfGasDay(date);
  const end = endOfGasDay(date);
  const range = moment.range(start, end);

  return Array.from(range.by('hour', { step: 1 }));
};

const getNextAvailableHour = () => moment.utc().add(3, 'hours').startOf('hour');

const getNextAvailableStart = (productPeriod, point) => {
  const getNext = period => getStartOfNthPeriod(startOfOngoingGasDay(), period, 1);

  switch (productPeriod) {
    case CAPACITY_PRODUCTS.HOURLY:
      return getNextAvailableHour();
    case CAPACITY_PRODUCTS.ANNUAL:
      return point === LOCATION_POINTS.IMATRA ? getNext('year') : getNext('month');
    default:
      return getNext(productInMomentTerm[productPeriod]);
  }
};

const convertNumber = (value, unit) => {
  const multiplier = new Decimal(
    unit === UNITS.MWH || unit === UNITS_PER_HOUR.MWH_PER_HOUR ? 0.001 : 1,
  );

  return Number(multiplier.mul(value));
};

const getTransferUpdateTimestamp = (transfer) => {
  if (!transfer || transfer.status === STATUS.PENDING) {
    return undefined;
  }

  return transfer.updatedAt;
};

// Some processed bookings might not contain a statusChangedAt field.
// In this case fall back to updatedAt field value.
const getUpdateTimestamp = (booking) => {
  if (!booking || booking.status === STATUS.PENDING) {
    return undefined;
  }
  return booking.statusChangedAt || booking.updatedAt;
};

const filterCapacityTypesForNetwork = (capacityType, selectedMarketParty) => {
  if (capacityType === CAPACITY_TYPES.LNG_INKOO) {
    return (
      selectedMarketParty
      && selectedMarketParty.network
      && selectedMarketParty.network.includes(NETWORKS.LNG_INKOO)
    );
  }
  return true;
};

export {
  calculateCapacityPrice,
  getEndDateForProduct,
  getTimeDifferenceInQty,
  getTotalPriceOfProducts,
  getDailyAggregates,
  getTransferUpdateTimestamp,
  getHourlyAggregate,
  productInMomentTerm,
  getActivePrices,
  getActivePricesForCapacityType,
  getInstanceStart,
  getHoursInAGasDay,
  getNextAvailableHour,
  getNextAvailableStart,
  hoursInBooking,
  convertNumber,
  getEndDate,
  getUpdateTimestamp,
  filterCapacityTypesForNetwork,
};
